<template>
  <div>
    <div class="page-header">
      <h1>{{ $tc("company", 2) }}</h1>
      <b-button
        type="is-primary"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'CompanyCreate' }"
      >
        {{ $t("company_create") }}
      </b-button>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <TableFilter
          :filter="filter"
          :labels="{ checkbox: $t('include_deactivated') }"
        ></TableFilter>

        <b-table
          ref="table"
          :current-page="page"
          :data="companies"
          :loading="loading"
          :total="total"
          backend-pagination
          backend-sorting
          :default-sort="order.by"
          :default-sort-direction="order.direction"
          class="is-clickable mt-2"
          hoverable
          paginated
          pagination-simple
          pagination-size="is-small"
          per-page="30"
          :row-class="(row) => row.blocked && 'has-text-grey-light'"
          @click="
            (e) =>
              this.$router.push({
                name: 'CompanyView',
                params: { id: e.id },
              })
          "
          @page-change="(e) => (page = e)"
          @sort="sort"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column :label="$t('name')" field="name" sortable>
            <template v-slot="props">
              {{ props.row.name }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('vat_number')">
            <template v-slot="props">
              {{ props.row.vatNumber }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('address')">
            <template v-slot="props">
              {{ addressString(props.row.address) }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('region')">
            <template v-slot="props">
              {{ props.row.region | region }}
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TableFilter from "../../components/TableFilter";

export default {
  name: "CompanyIndex",
  components: { TableFilter },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      companies: [],
      filter: {
        country: null,
        region: null,
        search: null,
        checkbox: false,
      },
      order: {
        by: "name",
        direction: "asc",
      },
    };
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getCompanies"]),
    load() {
      this.loading = true;

      this.getCompanies({
        page: this.page,
        country: this.filter.country,
        region: this.filter.region,
        name: this.filter.search,
        vatNumber: this.filter.search,
        address: this.filter.search,
        blocked: this.filter.checkbox,
        orderBy: this.order.by,
        orderDirection: this.order.direction,
      }).then(async ({ items, total }) => {
        this.companies = items;
        this.total = total;

        this.loading = false;
      });
    },
    sort(orderBy, orderDirection) {
      this.order.by = orderBy;
      this.order.direction = orderDirection;
      this.load();
    },
    addressString(address) {
      let string = `${address.address}, ${address.postcode} ${address.city}`;
      if (address.state) {
        string += `, ${address.state}`;
      }
      string += ` (${address.country})`;
      return string;
    },
  },
};
</script>
